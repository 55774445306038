import React from "react";

function Resume() {
  return (
    <>
      <div>Resume</div>
    </>
  );
}

export default Resume;
